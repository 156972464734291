import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchDepartmentList(params) {
  return axios.get(baseUrl + baseApiVersion + `/department/list`, { params, headers }
  );
}

export function fetchDepartment(departmentId) {
  return axios.get(baseUrl + baseApiVersion + `/department/${departmentId}`, { headers });
}

export function createDepartment(data) {
  return axios.post(baseUrl + baseApiVersion + `/department`, data, { headers });
}

export function updateDepartment(departmentId, data) {
  return axios.patch(baseUrl + baseApiVersion + `/department/${departmentId}`, data, {
    headers,
  });
}

export function deleteDepartment(departmentId) {
  return axios.delete(baseUrl + baseApiVersion + `/department/${departmentId}`, {
    headers,
  });
}