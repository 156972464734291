<template>
  <div class="modal team">
    <div class="box one mHeader flexB">
      <h1>소속선택</h1>
      <a @click="close"><i class="fas fa-times"></i></a>
    </div>
    <div class="box one mBody">
      <div class="tableBox">
        <table>
          <tr>
            <th><input type="checkbox" /></th>
            <th>{{ $t("event-pop-data-site-choose-dept-id") }}</th>
            <th>{{ $t("event-pop-data-site-choose-dept-name") }}</th>
            <th>{{ $t("event-pop-data-site-choose-dept-phone") }}</th>
            <th>{{ $t("event-pop-data-site-choose-dept-addr") }}</th>
          </tr>
          <tr v-for="(data, i) in departmentList" :key="i">
            <td>
              <input type="checkbox" v-model="department" :value="data" />
            </td>
            <td>{{ data.departmentId }}</td>
            <td>{{ data.name }}</td>
            <td>{{ data.phoneNumber }}</td>
            <td>{{ data.address + data.addressDetail }}</td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="total"
            :page-size="size"
            @current-change="handleCurrentChange"
            :current-page="currentPage + 1"
          >
          </el-pagination>
        </div>
      </div>
      <div class="buttonWrap">
        <button class="point large" @click="submit">{{ $t("btn-select") }}</button>
        <button class="large margin6" @click="close">{{ $t("btn-cancel") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchDepartmentList } from "@/api/department";
export default {
  data() {
    return {
      departmentList: [],
      department: [],
      currentPage: 0,
      total: 0,
      size: 10,
    };
  },
  mounted() {
    this.getDepartmentList();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getDepartmentList();
    },
    getDepartmentList() {
      let params = {
        pageNumber: this.currentPage, // pagination
        pageSize: this.size, // pagination
        statuses: "Used",
      };
      params["dateRange.from"] = null;
      params["dateRange.to"] = null;
      fetchDepartmentList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.departmentList = res.data.data.content;

        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    submit() {
      this.$emit("submit", this.department);
    },
  },
};
</script>
