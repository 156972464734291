<template>
  <div>
    <div class="title flexB">
      <h1 v-if="editMode">{{ $t("site-detail-title") }}</h1>
      <h1 v-else>{{ $t("site-register-title")}}</h1>
      <div>
        <!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
<!--        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>-->
      </div>
    </div>
    <div class="contents register">
      <div class="mainBox">
        <div class="flexL">
          <div v-if="editMode" class="flexL">
            <p class="bold">{{ $t("event-data-site-num") }}</p>
            <p>{{ viewId }}</p>
          </div>
          <div v-if="editMode" class="flexL">
            <p class="bold">{{ $t("event-data-site-status") }}</p>
            <select v-model="status">
              <option value="Ready">{{ $t("event-data-status-ready") }}</option>
              <option value="Open">{{ $t("event-data-status-on-going") }}</option>
              <option value="Close" v-if="status == 'Close' || oldStatus == 'Close'">{{ $t("event-data-status-close") }}</option>
            </select>
            <button class="small redBtn" v-show="oldStatus != 'Close'" @click="handleTerminate" >
              {{ $t("event-data-site-shut-down") }}
            </button>
          </div>
        </div>

        <div class="flexL" >
          <div class="flexL">
            <p class="bold">{{ $t("event-data-site-name") }}<span>*</span></p>
            <input type="text" v-model="title" style="width:35rem;"/>
          </div>

          <div class="flexL">
            <p class="bold">{{ $t("event-data-site-dept") }}<span>*</span></p>
            <select name="" v-model="department">
              <option disabled value>{{ $t("select-box-default-choose") }}</option>
              <option
                  v-for="(item, i) in departmentList"
                  :key="i"
                  :value="item.departmentId"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

        </div>


        <div class="flexL address" style="margin-left: 5px;">
          <p class="bold">{{ $t("event-data-site-addr") }}<span>*</span></p>
          <button class="small" @click="execDaumPostcode">
            {{ $t("btn-addr-search") }}
          </button>
          <input type="text" v-model="address" disabled/>
          <input type="text" v-model="addressDetail"/>
        </div>
        <!--        <div class="flexL">
                  <p class="bold">현장위치<span>*</span></p>
                  <button class="small" @click="MapOpen">
                    지도검색
                  </button>
                </div>-->
        <div class="flexL">
          <div class="flexL">
            <p class="bold">{{ $t("event-data-site-correct-range") }}<span>*</span></p>
            <input type="text"
                   :value="positionRange"
                   @input="bindRangeNumber"
                   @blur="endBindRangeNumber"
            />
            <span>Km ({{ $t("event-data-site-correct-within-size") }})</span>
          </div>
        </div>
        <div class="flexL">
          <div class="flexL">
            <p class="bold">{{ $t("event-data-site-manager") }}<span>*</span></p>
            <input type="text" class="small" v-model="managerName"/>
          </div>

        </div>
        <div class="flexL">
          <div class="flexL">
            <p class="bold">{{ $t("event-data-site-phone") }}</p>
            <input
                type="text"
                class="small"
                :value="managerPhone"
                @input="bindPhoneNumber"
            />
          </div>
          <div class="flexL">
            <p class="bold">{{ $t("event-data-site-email") }}</p>
            <input type="text" class="small" v-model="managerEmail"/>
          </div>
        </div>
        <!--현장체크인 비밀번호(제외)-->
        <!--        <div class="flexL">
                  <p class="bold">{{ $t("393") }}<span>*</span></p>
                  <input type="password" v-model="password" @keyup="removeChar"/>
                  <p class="description">
                    {{ $t("394") }}
                  </p>
                </div>-->
        <div class="flexL">
          <p class="bold">{{ $t("event-data-site-restrict-info") }}<span>*</span></p>
          <label
          ><input
              type="radio"
              name="access"
              checked
              :value="false"
              v-model="isAuth"
          />
            <span>{{ $t("event-data-site-radio-restrict") }}</span></label
          >
          <label
          ><input
              type="radio"
              name="access"
              :value="true"
              v-model="isAuth"
          />
            <span>{{ $t("event-data-site-radio-no-restrict") }}</span>
          </label
          >
          <p class="description">
            (
            {{ $t("event-data-site-restrict-description") }}
            )
          </p>
        </div>
        <div class="flexL" v-show="isAuth">
          <p class="bold">{{ $t("event-data-site-accessible") }}</p>
          <button class="small" @click="handleTab(1)">{{ $t("btn-dept-search") }}</button>
          <ul>
            <li>{{ department.name }}</li>
            <li v-for="(data, i) in selectDepartment" :key="i">
              {{ data.name }}
              <img
                  src="@/assets/images/icon_svg/ic_delete_gr.svg"
                  alt="icon"
                  @click="deleteDepartment(i)"
              />
            </li>
            <!-- <li>
              은평소방서
              <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" />
            </li>
            <li>
              강동소방서
              <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" />
            </li> -->
          </ul>
        </div>
        <div v-if="editMode" class="flexL">
          <p class="bold">{{ $t("event-data-site-correct-register") }}</p>
          <p>
            {{ $t("event-data-site-correct-register-date") }}
            {{ moment(registerDate).format("YYYY.MM.DD HH:mm:ss") }} /
            {{ $t("event-data-site-correct-last-date") }}
            {{ moment(modifyDate).format("YYYY.MM.DD HH:mm:ss") }}
          </p>
        </div>
        <div v-if="editMode" class="flexL">
          <p class="bold">{{ $t("event-data-site-correct-registrant") }}</p>
          <p>
            {{ registerUserName }}({{
              eventObj.registerLoginId ? eventObj.registerLoginId : "-"
            }})
          </p>
        </div>

        <div class="buttonWrap">
          <button
              class="large left"
              @click="handleDelete(eventId)"
              v-if="canDelete"
          >
            {{ $t("btn-delete") }}
          </button>
          <div>
            <button class="point large" @click="submit">{{ $t("btn-confirm") }}</button>
            <button class="large" @click="handleCancel">{{ $t("btn-cancel") }}</button>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="visible == 1" class="dim on">
        <!-- <div class="dim on"> -->
        <SelectTeam @close="close" @submit="addDepartment"/>
      </div>
      <div v-if="visible == 2" class="dim on">
        <!-- <div class="dim on"> -->
        <!-- <SearchAddress /> -->
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import SelectTeam from "@/components/pop/SelectTeam";
// import SearchAddress from "@/components/pop/SearchAddress";
// import { mapState } from "vuex";
import {sido} from "@/util/address";
import {createEvent, fetchEvent, updateEvent, deleteEvent,} from "@/api/event";
import {fetchDepartmentList} from "@/api/department";

export default {
  components: {
    SelectTeam,
    //  SearchAddress
  },
  name: "Register",
  data() {
    return {
      moment: moment,
      accountId: localStorage.getItem("accountId"),
      visible: false,
      editMode: false,
      department: "",
      eventId: "",
      viewId:"",
      /*password: "",*/
      title: "",
      status: "",
      address: "",
      addressDetail: "",
      oldAddress: "",
      oldAddressDetail: "",
      joinCode: "",
      addressZipCode: "",
      channelCount: "",
      registerUserId: "",
      openDate: "",
      closeDate: "",
      registerDate: "",
      modifyDate: "",
      managerName: "",
      managerPhone: null,
      managerEmail: "",
      managerAccount: "",
      registerUserName: "",
      hide: false,
      auth: false,
      isHide: false,
      isAuth: false,
      departmentList: [],
      selectDepartment: [],
      positionRange: null,
      eventObj: {},

      oldStatus : null,
      orgStatus: null,
      canDelete: false,
    };
  },
  mounted() {
    if (this.$route.query.eventId) {
      this.eventId = this.$route.query.eventId;
      this.editMode = true;
      this.getEventDetail();
    }
    this.getDepartmentList();


  },
  watch : {
    positionRange(val){
      let check = /^[0-9.]+$/;
      if (!check.test(val)) {
        this.positionRange = val.replace(/[^0-9]/g,'');
      }

      if(this.positionRange >= 10){
        this.positionRange = 10;
        return
      }

      var split = this.positionRange.toString().split(".")
      if(split.length > 1){
        if(split[1].length > 1){
          var temp = split[1].substring(0,1)
          this.positionRange = split[0] + "." + temp
        }
      }
    },
    managerPhone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.managerPhone = val.replace(/[^0-9]/g,'');
      }
    },
  },
  // computed: {
  //   ...mapState(["userId"]),
  // },
  methods: {
    bindRangeNumber(event){
      this.positionRange = event.target.value;
    },
    endBindRangeNumber(event){
      var split = event.target.value.toString().split(".")
      if(split.length > 1){
        if(split[1] == ""){
          this.positionRange = split[0] + "." + 0
        }
      }
    },
    bindPhoneNumber(event){
      this.managerPhone = event.target.value;
    },
    /**지도 선택*/
    /*MapOpen() {
      this.index = this.index + 1;
      let accountId = localStorage.getItem("accountId");
      window.open(
          `/${accountId}/mapChoice?eventId=${this.eventId}`,
          `event${this.index}`,
          "height=650,width=1024,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
      );
    },*/
    //현장체크인 비밀번호
    close() {
      this.visible = false;
    },
    addDepartment(data) {
      this.visible = false;
      this.selectDepartment = data;
    },
    deleteDepartment(i) {
      this.selectDepartment.splice(i, 1);
    },
    execDaumPostcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          var  jibunAddress = data.jibunAddress ? data.jibunAddress : data.autoJibunAddress;
          let jibunArray = jibunAddress.split(" "); // 지번 배열화
          if(sido[jibunArray[0]] != undefined) {
            jibunArray[0] = sido[jibunArray[0]]; // 배열[0](시 이름)을 시 풀네임으로 변경
          }
          this.oldAddress = jibunArray.join(" "); // 변경된 주소 합침

          let addArray = data.address.split(" ");
          if(sido[addArray[0]] != undefined) {
            addArray[0] = sido[addArray[0]];
          }

          this.address = addArray.join(" ") + (data.buildingName ? "(" + data.buildingName + ")" : "");
          this.postcode = data.zonecode;
        },
      }).open();
    },
    handleDelete() {
      if (!confirm(this.$t("event-register-confirm-want-to-delete"))) {
        return;
      }

      deleteEvent(this.eventId).then((res) => {
        if (res.status == 200) {
          this.$router.push(`/${this.accountId}/event/list`);
        }
      });
    },
    handleCancel() {
      this.$router.push(`/${this.accountId}/event/list`);
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.visible = id;
    },
    getDepartmentList() {
      var params = {
        statuses : "Used"
      }
      fetchDepartmentList(params).then((res) => {
        this.departmentList = res.data.data.content;
      });
    },
    handleTerminate() {
      this.status = "Close";
    },
    setEventDetail(){
      this.eventId = this.eventObj.eventId;
      this.viewId = this.eventObj.viewId;
      this.department = this.eventObj.managerDepartment.departmentId;
      this.selectDepartment = this.eventObj.departmentList;
      this.title = this.eventObj.title;
      this.status = this.eventObj.status;
      this.address = this.eventObj.address;
      this.addressDetail = this.eventObj.addressDetail;
      this.oldAddress = this.eventObj.oldAddress;
      this.oldAddressDetail = this.eventObj.oldAddressDetail;
      this.joinCode = this.eventObj.joinCode;
      this.addressZipCode = this.eventObj.addressZipCode;
      this.channelCount = this.eventObj.channelCount;
      this.registerUserId = this.eventObj.registerUserId;
      this.openDate = this.eventObj.openDate;
      this.closeDate = this.eventObj.closeDate;
      this.registerDate = this.eventObj.registerDate;
      this.modifyDate = this.eventObj.modifyDate;
      this.managerName = this.eventObj.managerName;
      this.managerPhone = this.eventObj.managerPhone;
      this.managerEmail = this.eventObj.managerEmail;
      this.managerAccount = this.eventObj.managerAccount;
      //this.password = res.data.data.password;
      this.hide = this.eventObj.hide;
      this.auth = this.eventObj.auth;
      this.isHide = this.eventObj.isHide;
      this.isAuth = this.eventObj.isAuth;
      this.positionRange = this.eventObj.positionRange;
      this.registerUserName = this.eventObj.registerUserName;
      this.oldStatus = this.status
      this.canDelete = this.status == 'Ready' || this.status == 'Close'
    },
    getEventDetail() {
      let eventId = this.$route.query.eventId;

      if(eventId == undefined) {
        return;
      }
      fetchEvent(eventId).then((res) => {
        if (res.status == 200) {
          this.eventObj = res.data.data;
          this.setEventDetail();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    submit() {
      if (this.editMode && this.oldStatus != this.status ) {
        if (!confirm(this.$t("event-register-alert-changeStatus"))) {
          return;
        }
      }


      if (this.title == "") {
        return alert(this.$t("alert-message-check-event-name"));
      } else if (!this.positionRange || this.positionRange > 10) {
        return alert(this.$t("alert-message-check-event-range"));
      } else if (this.address == "") {
        return alert(this.$t("alert-message-check-addr"));
      } else if (this.managerName == "") {
        return alert(this.$t("alert-message-check-dept-manager"));
      } else if (!this.department) {
        return alert(this.$t("alert-message-check-dept"));
      } else if(this.selectDepartment.length == 0 && this.isAuth == true){
        return alert(this.$t("alert-message-check-auth-dept-list"));
      }

      let data = {
        accountId: this.accountId,
        addressZipCode: this.addressZipCode,
        registerUserId: localStorage.getItem("userId"),
        positionRange: Number(this.positionRange),
        address: this.address,
        addressDetail: this.addressDetail,
        title: this.title,
        oldAddress: this.oldAddress,
        oldAddressDetail: this.addressDetail,
        managerName: this.managerName,
        isHide: this.isHide,
        managerEmail: this.managerEmail,
        isAuth: this.isAuth,
        //password: this.password,
        managerPhone: this.managerPhone,
        joinCode: this.joinCode,
        status: this.status ? this.status : undefined,
        managerDepartmentId: this.department,
        departmentList: this.selectDepartment,
      };

      if (this.editMode) {
        let eventId = this.$route.query.eventId;
        updateEvent(eventId, data).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-data-save"));
            this.$router.push({name: "eventList"});
          } else {
            let message = res.data.message;
            alert(message);
          }
        })
            .catch((error) => {
              let resultCode = error.response.data.result
              if(resultCode < 0) {
                if(resultCode == -1){
                  alert(this.$t("event-modify-status-change-alert"));
                }

                if(resultCode == -200 ) {
                  alert(this.$t("event-modify-status-change-open-to-ready-alert"));
                }
                this.setEventDetail()
              }
            })
        ;
      } else {
        createEvent(data).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-data-save"));
            this.$router.push({name: "eventList"});
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>